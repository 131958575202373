<template>
    <div class="row">
        <div class="row" style="border-radius: 0; padding: 1%">
            <h4>Assignment Management</h4>
            <div class="row">
                <div class="col">
                    <label>Last Updated By:</label>
                    <h6>{{ lastUpdatedBy }}</h6>
                </div>
                <div class="col">
                    <label>Last Updated On:</label>
                    <h6>{{ lastUpdated }}</h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <label>Filter By:</label>
                <input class="form-control" type="search" v-model="searchQuery"
                       placeholder="Name, Module, Url, Canvas Id" aria-label="Search Query"/>
            </div>
            <div class="col-sm">
                <b-tooltip target="tooltip-target-update" triggers="hover"
                           title="This will resync assignment data with Canvas. This will take a while">
                    <button class="bi bi-arrow-repeat btn-primary button" @click="updateParaAssignments"> Para
                        Assignments
                    </button>
                </b-tooltip>
            </div>
            <div class="col-sm">
                <b-tooltip target="tooltip-target-update" triggers="hover"
                           title="This will resync assignment data with Canvas. This will take a while">
                    <button class="bi bi-arrow-repeat btn-primary button" @click="updateInstructorAssignments">
                        Supervisor
                        Assignments
                    </button>
                </b-tooltip>

            </div>
        </div>
    </div>
    <div v-if="!isLoading">
        <table id="assignmentTable" class="table table-bordered table-striped">
            <thead>
            <tr>
                <th v-for="(value, name, index) in assignmentHeaders" :key="index" @click="sortTable(name)">
                    <i :class="name === prevSortCol ? sortIconStyle : 'bi bi-sort-up'"></i>
                    {{ value }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in filteredList" :key="item">
                <td v-for="(value, name, index) in assignmentHeaders" :key="index">
                    <i v-if="name === 'certificationNames'" style="font-style: normal;">
                        <p v-for="certification in item.certificationNames" :key="certification">{{ certification }}</p>
                    </i>
                    <i v-else-if="name === 'htmlUrl'" style="font-style: normal;">
                        <a :href="item['htmlUrl']">{{ item[name] }}</a>
                    </i>
                    <i v-else-if="name === 'idAssignment'" style="font-style: normal;">
                        <div class="row">

                            <b-tooltip target="trash-icon"
                                       title="Clicking this will delete the assignment in ESUCC tables.">
                                <button style="color: red;" @click="deleteAssignment(item['idAssignment'])">&#128465;Delete</button>
                            </b-tooltip>
                        </div>
                    </i>
                    <p v-else>{{ item[name] }}</p>
                </td>
            </tr>
            </tbody>
        </table>

        <label>Assignments Found: {{ filteredCount }}</label>
    </div>
    <div v-else class="row">
        <Loader :loading-value="'Assignments from Canvas'"/>
    </div>


</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import {sortBy} from "lodash/collection";
import Swal from "sweetalert2";
import Loader from "@/components/Loader";
import {updateAssignments} from "@/views/SuperUserViews/ESUCC/AssignmentAPICalls";

export default {
	name: "AssignmentManagement",
	components: {Loader},
	setup() {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		const authUser = computed(() => store.getters['auth/user']);
		const sort = ref(false);
		const prevSortCol = ref("assignmentName");
		const updatedList = ref([]);
		const searchQuery = ref("");
		const isLoading = ref(false);
		const trashIcon = ref('\uF5DE');
		const colReversed = ref(false);

		const assignments = ref([]);
		const assignmentHeaders = ref({
			assignmentName: "Name",
			canvasId: "Canvas Id",
			htmlUrl: "URL to Canvas",
			moduleName: "Module",
			certificationNames: "Certifications",
			pointsPossible: "Points Possible",
			idAssignment: "Actions"
		});

		onBeforeMount(() => {
			if (authUser.value.esu.idEsu !== 20) {
				location.replace('/dashboard');
			}
			getAssignments();
		})

		async function getAssignments() {
			let get_uri = API_URL + "/assignments/all";
			await axios.get(get_uri)
				.then((result) => {
					assignments.value = result.data.sort((a, b) => a.assignmentName > b.assignmentName ? 1 : -1);
					updatedList.value = assignments.value;
				})
		}

		function updateParaAssignments() {
			let get_uri = updateAssignments(261);
			Swal.fire({
				title: 'Updating Assignments',
				text: 'Updating the Assignments from Canvas will take some time. After the process is complete, you will see the updated assignments.',
				confirmButtonText: 'Okay',
				cancelButtonText: 'Cancel',
				icon: "info"
			}).then((result) => {
				if (result.isConfirmed) {
					isLoading.value = true;
					updateAssignmentsHelper(get_uri);
				}
			})
		}

		function updateInstructorAssignments() {
			let get_uri = updateAssignments(268);
			Swal.fire({
				title: 'Updating Assignments',
				text: 'Updating the Assignments from Canvas will take some time. After the process is complete, you will see the updated assignments.',
				confirmButtonText: 'Okay',
				cancelButtonText: 'Cancel',
				icon: "info"
			}).then((result) => {
				if (result.isConfirmed) {
					isLoading.value = true;
					updateAssignmentsHelper(get_uri);
				}
			})
		}

		async function updateAssignmentsHelper(getUri) {
			await axios.get(getUri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				if (result.status === 200) {
					getAssignments();
				}
			}).finally(() => {
				isLoading.value = false;
			})
		}

		function deleteAssignment(idAssignment) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You are about to delete this assignment.',
				confirmButtonText: 'Okay',
				cancelButtonText: 'Cancel',
				showCancelButton: true,
				icon: 'warning'
			}).then((result) => {
				if (result.isConfirmed) {
					deleteAssignmentHelper(idAssignment);
				} else {
					Swal.fire({
						title: 'Did not delete this assignment'
					})
				}
			})
		}

		async function deleteAssignmentHelper(idAssignment) {
			let delete_uri = API_URL + "/assignments/delete/" + idAssignment;
			await axios.delete(delete_uri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				if (result.status === 200) {
					Swal.fire({
						title: 'Successfully Deleted',
						text: 'Deleted that assignment in the ESUCC data'
					})
				}
			}).finally(() => {
				getAssignments();
			})
		}


		const sortTable = (col) => {
			sort.value = true;

			if (col !== prevSortCol.value) {
				updatedList.value = sortBy(assignments.value, col);
				colReversed.value = false;
			} else {
				updatedList.value = updatedList.value.reverse();
				colReversed.value = !colReversed.value;
			}
			prevSortCol.value = col;
		}

		const filteredList = computed(() => {
			return updatedList.value.filter((assignment) => {
				const name = assignment.assignmentName.toLowerCase();
				const canvas_id = assignment.canvasId.toString();
				const url = assignment.htmlUrl.toLowerCase();
				const module_name = assignment.moduleName.toLowerCase();

				return (name !== "" && name.includes(searchQuery.value.toLowerCase())) || (url !== "" && url.includes(searchQuery.value.toLowerCase()))
					|| (module_name !== "" && module_name.includes(searchQuery.value.trim())) || (canvas_id.includes(searchQuery.value));
			})
		})

		const filteredCount = computed(() => {
			return filteredList.value.length;
		})

		const mostRecentlyUpdatedAssignment = computed(() => {
			let assignments_with_updates = assignments.value.filter((a) => a.lastUpdate !== null);
			assignments_with_updates.sort((a, b) => new Date(a.lastUpdate).getTime() / 100 < new Date(b.lastUpdate).getTime() / 100 ? 1 : -1);
			return assignments_with_updates[0];
		})

		const lastUpdated = computed(() => {
			return mostRecentlyUpdatedAssignment.value.lastUpdate;
		})

		const lastUpdatedBy = computed(() => {
			return mostRecentlyUpdatedAssignment.value.lastUpdateBy;
		})

		const sortIconStyle = computed(() => {
			return !colReversed.value ? 'bi bi-sort-down' : 'bi bi-sort-up';
		})

		return {
			updatedList,
			filteredList,
			assignmentHeaders,
			searchQuery,
			filteredCount,
			isLoading,
			trashIcon,
			lastUpdated,
			lastUpdatedBy,
			prevSortCol,
			sortIconStyle,
			deleteAssignment,
			sortTable,
			updateParaAssignments,
			updateInstructorAssignments
		}
	}
}
</script>

<style scoped>
td {
    border: 1px solid black;
    color: black;
}

th {
    border: 1px solid black;
    color: black;
}

</style>
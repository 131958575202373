import {API_URL} from "../../../../Constants";

export const updateAssignments = (canvasCourseId) => {
    return `${API_URL}/assignments/update/${canvasCourseId}`;
}

const deleteAssignments = (idAssignment) => {
    return `${API_URL}/assignments/delete/${idAssignment}`;
}


<template>
  <div class="col-sm">
    <h6>{{ message }} </h6>
    <div class="loader" :style="`height: ${height}; width: ${width}`"></div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "Loader",
  props: {
    loadingValue: String,
    height: String,
    width: String
  },
  setup(props) {
    const message = computed(() => {
      return props.loadingValue.indexOf("Updating") !== -1 ? props.loadingValue : `Loading ${props.loadingValue}`
    });

    return {
      message,
    }
  }
}
</script>

<style scoped>
.loader {
  border: 16px solid lightgrey;
  border-top: 16px solid slateblue;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>